<template>
  <div class="modal-container">
    <div class="modal-header">
    </div>
    <div class="modal-center">  
      <img src="@/assets/images/IA/re_password_success.png" alt="s uccess">
      <p>비밀번호 재설정이 완료되었습니다.</p>
    </div>
    <div class="modal-footer">
      <button class="btn-confirm" @click="btnConfirm">확인</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RePasswordDialog",
  methods: {
    btnConfirm() {
      const self = this;
      self.$emit('callBackModal')
    },
  }
}
</script>

<style scoped>

.modal-container {
  position: fixed;
  width: 483px; height: 313px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background: #FFFFFF;
  border-radius: 8px;

}

.modal-header {
  height: 73px;
  border: 0;
}
.modal-footer { 
  height: 72px;
  border: 0; 
  }

.modal-center {
  padding: 30px 60px;
}
.modal-center img {
  width: 60px;
  height: 60px;
}
.modal-center p {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: rgba(24, 24, 25, 0.9);
  margin-top: 20px;
}

.btn-confirm {
  width: 109px;
  height: 40px;
  background: #4C9AF5;;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #FFFFFF;
  border: 0;
}

</style>
