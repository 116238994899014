<template>
  <div class="main-container">
    <div class="modal-cover" v-show="isShowModal"></div>

    <div class="re-password-form">
      <form>
        <h1>비밀번호 재설정</h1>
        <div class="label-box">
          <label>
            새로운 비밀번호
          </label>
          <input type="password" placeholder="8자리 이상 입력" class="custom-input-box" v-model="password" autocomplete="off">
        </div>
        <div class="label-box">
          <label>
            새로운 비밀번호 확인
          </label>
          <input type="password" placeholder="8자리 이상 입력" class="custom-input-box" v-model="rePassword" autocomplete="off"
                 v-on:keypress.enter.prevent="updatePassword">
        </div>
      </form>
      <button class="custom-btn-box" @click="updatePassword">비밀번호 재설정</button>
    </div>

    <Progress :isProgress="isProgress"/>
    <RePasswordDialog @callBackModal="callBackModal" v-show="isShowModal"/>
  </div>
</template>

<script>
import RePasswordDialog from "@/views/account/RePasswordDialog";
import Progress from '../../components/Progress.vue'
import {firebaseError} from "@/firebase/firebaseError";
import {firebase} from "@/firebase/firebaseConfig";

export default {
  name: 'RePassword',
  components: {Progress, RePasswordDialog},
  data() {
    return {
      password: '',
      rePassword: '',
      isProgress: false,
      isShowModal: false,
    }
  },
  methods: {
    updatePassword() {
      const self = this;
      if (self.password.trim().length < 8) {
        alert('비밀번호는 최소 8자리 이상입니다.')
        return;
      }
      if (self.password.trim().length === 0) {
        alert('비밀번호를 입력하세요.')
        return;
      }
      if (self.rePassword.trim() !== self.password.trim()) {
        alert('패스워드가 일치하지 않습니다.')
        return;
      }
      const user = firebase.auth().currentUser;
      const newPassword = self.password;

      user.updatePassword(newPassword)
          .then(() => {
            self.isShowModal = true;
          })
          .catch((error) => {
            firebaseError(error)
          });
    },

    callBackModal: function () {
      const self = this;
      self.isShowModal = false
      self.$router.replace('/myInfo');
    },
  }
}
</script>

<style scoped>

.custom-input-box {
  font-size: 16px;
}

strong {
  margin-left: 13px;
}

.main-container {
  background-color: #4C9AF5;
  height: 638px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.re-password-form {
  width: 418px;
  height: 396px;
  background: #FFFFFF;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
}

.re-password-form h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 40px;
}

.re-password-form label {
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #272833;
  margin-bottom: 10px;
  text-align: left !important;
}

.label-box input {
  outline: none;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.0025em;
  margin-bottom: 0;
}

.label-box {
  text-align: left;
  margin-bottom: 20px;
}

.custom-btn-box {
  margin-top: auto;
  background: #2B2346;
}
</style>


